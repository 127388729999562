<template>
  <!-- <main-wrapper manage :style="{overflowY:isAnimating?'hidden':'auto'}" :tool="false"> -->
    <div class="v-wrapper tool--none">
      <div class="v-body">
        <template>
          <div class="search-bars">
            <!-- <tool-tip :content="$t('userManage.addHold')" placement="top" type="light-tip" :offset="-10">
            <el-button @click="addHandle" type="primary" v-if="loginInfo.allFuns.includes(5011)" plain circle v-ripple>
              <i-icon name="iconxinxiguanli_tianjia_weixuanzhong"></i-icon>
            </el-button>
            </tool-tip> -->
            <!-- <tool-tip :content="$t('userManage.deleteChecked')" placement="top" type="light-tip" :offset="-10">
            <el-button @click="deleteHandle"  type="primary" v-if="loginInfo.allFuns.includes(5013)" plain circle v-ripple>
               <i-icon name="iconshanchu"></i-icon>
            </el-button>
            </tool-tip> -->
            <!-- <tool-tip :content="$t('userManage.changeParent')" placement="top" type="light-tip" :offset="-10">
            <el-button @click="rowChange($refs.multipleTable.selection)" type="primary" plain circle v-ripple>
               <i-icon name="iconxinxiguanli_qianyi_weixuanzhong"></i-icon>
            </el-button>
            </tool-tip> -->
            <!-- <tool-tip :content="$t('userManage.export')" placement="top" type="light-tip" :offset="-10">
            <el-button @click="exportHandle" type="primary" plain circle :loading="exportButtonLoading" v-ripple>
              <i-icon name="iconxinxiguanli_daochu_weixuanzhong" v-if="!exportButtonLoading"></i-icon>
            </el-button>
            </tool-tip> -->
            <div class="e-radio"  @click="addHandle" type="primary" v-if="loginInfo.allFuns.includes(5011)">添加</div>
            <div class="e-radio"  @click="deleteHandle"  type="primary" v-if="loginInfo.allFuns.includes(5013)">删除</div>
            <div class="e-radio"  @click="rowChange($refs.multipleTable.selection)">迁移</div>
            <div class="e-radio" @click="exportHandle" v-loading="exportButtonLoading">导出</div>

            <search-group style="float:right;" :options="options" @click="click" @input="({select,value,Op}) => {searchName=select;searchText=value;searchOption=Op} "
              :field="{select: searchName, value: searchText}"></search-group>
            <el-checkbox v-model="hasChild" class="search-containChild">{{$t('userManage.includeChild')}}</el-checkbox>
            <label class="search-label align-right imr-1">{{$t('common.queryItem')}}:</label>
          </div>

          <el-table :data="tableData" class="v-table" border @sort-change="sortChange" @header-dragend="saveHeaderDragWidth" size="mini" :height="tableHeight" ref="multipleTable" v-loading="tableLoading" :element-loading-text="tableLoadingText">
            <el-table-column type="selection" width="55" :selectable="selectableHandle"></el-table-column>
            <el-table-column :label="$t('userManage.userName')" sortable="custom" prop="Name" :width="TW($t('userManage.userName'), 120)" :show-overflow-tooltip="false">
              <template slot-scope="{row}">
                <hold-popover :item-id="row.HoldID">
                <span class="i-link" style="overflow:hidden;"  @click.stop="rowClick(row)">{{ row.HoldName }}</span>
                </hold-popover>
              </template>
            </el-table-column>
            <el-table-column sortable="custom" prop="UserName" :label="$t('userManage.account')" :width="TW($t('userManage.account'), 120)"></el-table-column>
            <el-table-column sortable="custom" prop="Contacter" :label="$t('userManage.contacer')" :width="TW($t('userManage.contacer'), 120)">
              <span slot-scope="{row}" :title="row.HoldID">{{row.Contacter}}</span>
            </el-table-column>
            <el-table-column sortable="custom" prop="MustTel" :label="$t('userManage.phone')" :width="TW($t('userManage.phone'), 135)"></el-table-column>
            <el-table-column sortable="custom" prop="CreateTime" :label="$t('userManage.createTime')" :width="TW($t('userManage.createTime'), 120)">
              <template slot-scope="{row}">{{miment(row.CreateTime).format('YYYY-MM-DD')}}</template>
            </el-table-column>
            <el-table-column prop="Remark" :label="$t('userManage.remark')" :width="TW($t('userManage.remark'), 120)" show-overflow-tooltip></el-table-column>
            <el-table-column sortable="custom" prop="FullPathName" :label="$t('userManage.fullPath')" :minWidth="TW($t('userManage.fullPath'), 240)" show-overflow-tooltip></el-table-column>
            <el-table-column label="" width="100px" :resizable="false">
              <template slot-scope="{row}">
                <i-drop-down trigger="click">
                  <span slot="active" class="i-link">{{$t('common.operate')}}<i class="el-icon-caret-bottom" style="margin-left:0.5em;"></i></span>
                  <i-drop-down-option :label="$t('common.modify')" value="0" @click="rowClick(row)" v-if="loginInfo.allFuns.includes(5012)"></i-drop-down-option>
                  <i-drop-down-option :label="$t('common.migrate')" value="1" @click="rowChange([row])" v-if="loginInfo.allFuns.includes(5012)"></i-drop-down-option>
                  <i-drop-down-option :label="$t('common.delete')" value="2" @click="deleteHandle([row])" v-if="loginInfo.allFuns.includes(5013)"></i-drop-down-option>
                </i-drop-down>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            class="v-pager"
            background
            size='mini'
            :page-size="pageSize"
            :page-sizes="pageSizes"
            :pager-count="11"
            :current-page="pageIndex"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotalCount"
            @current-change="pageCurrentChange"
            @size-change="pageSizeChange"
            popper-class="custom">
          </el-pagination>

          <transition name="slide-top" @before-enter="isAnimating=true" @before-leave="isAnimating=true" @after-enter="isAnimating=false" @after-leave="isAnimating=false">
            <router-view @refresh="refreshHandle"></router-view>
          </transition>

          <el-dialog :title="$t('deviceManage.moverDevices')" custom-class="v-dialog v-dialog--sm v-dialog--mdpadding v-gs-dialog" :visible.sync="dialogChangeParent">
            <change-parent :datas="changedRow" v-if="dialogChangeParent" :old-hold-info="{id:currentHoldId,name:currentHoldName}" mode="user" @close="dialogChangeParent=false" @refresh="refreshHandle"></change-parent>
          </el-dialog>

          <detail-hold ref="detail" @refresh="refreshHandle"></detail-hold>
        </template>
      </div>
    </div>
  <!-- </main-wrapper> -->
</template>

<script>
import { VclCode } from 'vue-content-loading'
import SearchGroup from './components/search-group'
import IDropDown from '@/base/i-dropdown/i-dropdown'
import IDropDownOption from '@/base/i-dropdown/i-dropdown-option'
import mixin from './mixin'
import * as API from '@/api/hold'
import {Filter, Op} from '@/api/config'
import DetailHold from './detail/detail-hold'
import { saveBlob } from '@/common/utils'
import miment from 'miment'
import HoldPopover from '@/views/common/hold-popover'
import {REFRESH_TREE_USER} from '@/common/bus-types'

export default {
  name: 'manageHold',
  data() {
    return {
      miment,
      options: [
        { label: '用户名称', value: 'H.Name', Op: Op.Contains },
        { label: '登录账号', value: 'U.UserName', Op: Op.Contains },
        { label: '联系人', value: 'H.Contacter', Op: Op.Contains },
        { label: '备注', value: 'H.Remark', Op: Op.Contains }
      ],
      isAnimating: false // 是否正在动画
    }
  },
  components: {
    IDropDown,
    IDropDownOption,
    SearchGroup,
    VclCode,
    DetailHold,
    HoldPopover
  },
  mixins: [
    mixin
  ],
  watch: {
    '$i18n.locale': 'getOptions'
  },
  created() {
    this.getOptions()
    this.pageIndex = 1
    this.queryHandle()
  },
  methods: {
    click() {
      this.pageIndex = 1
      this.queryHandle()
    },
    rowClick(row) {
      //this.$router.push(`/manage/hold/detail?id=${row.HoldID}`)
      this.$refs.detail.open(row.HoldID)
    },
    getOptions () {
      this.options = [
        { label: this.$t('userManage.userName'), value: 'H.Name', Op: Op.Contains },
        { label: this.$t('userManage.account'), value: 'U.UserName', Op: Op.Contains },
        { label: this.$t('userManage.contacer'), value: 'H.Contacter', Op: Op.Contains },
        { label: this.$t('userManage.remark'), value: 'H.Remark', Op: Op.Contains }
      ]
    },
    selectableHandle() {
      return true
      //return row.UserID != this.loginInfo.UserID
    },
    addHandle() {
      // this.$router.push('/manage/hold/detail')
      this.$refs.detail.open()
    },
    deleteHandle(rows) {
      if (!(rows instanceof Array)) {
        rows = null
      }

      const targetRows = rows || this.$refs.multipleTable.selection

      if (targetRows.length <= 0) {
        this.$alert(this.$t('common.emptySelectedTip'), '', {
          type: 'error'
        })
        return
      }

      let msg0 = this.$t('common.deleteTip')
      this.$confirm(msg0, this.$t('common.warn'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {

        this.deleteButtonLoading = true
        API.Delete(targetRows.map(k => k.HoldID)).then(ret => {
          // 从本地删除
          // targetRows.forEach(item => {
          //   deleteFromArray(this.tableData, row => {
          //     return row == item
          //   })
          // })

          if (ret.data.errCode) {
            this.$message({
              type: 'error',
              message: ret.data.errMsg,
              showClose: true
            });
            return
          }

          this.$message({
            type: 'success',
            message: this.$t('common.deleteSuccessed'),
            showClose: true
          });

          if ((targetRows.length < this.pageSize)
            && (targetRows.length < this.tableData.length)) {
            this.pageIndex = this.pageIndex > 1 ? this.pageIndex - 1 : 1
          }
          this.pageIndex = 1
          this.queryHandle()
          this.$bus.$emit(REFRESH_TREE_USER)
        }).catch(err => {
          this.print('error', err.message)
        })

      }).catch(() => {

      });
    },
    queryHandle() {
      const promise = new Promise((resolve, reject) => {
        const filters = []

        this.searchText.length > 0
          && (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))
        this.tableLoading = true
        API.Query(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters, this.currentHoldId, this.hasChild).then(ret => {
          //console.log(ret)
          this.tableData = ret.data.list.slice()
          this.pageTotalCount = ret.data.total
          this.tableLoading = false
          resolve()
        }).catch(err => {
          this.print('error', err.message)
          this.tableLoading = false
          reject(err)
        })
      })
      return promise
    },
    exportHandle() {
      this.exportButtonLoading = true
      API.Export(this.currentHoldId, this.hasChild).then(ret => {
        this.exportButtonLoading = false
        saveBlob(ret.data, `${this.$t('holdDetail.name')}.xlsx`)
      })
    },
    refreshHandle() {
      this.pageIndex = 1
      this.queryHandle()
      this.$bus.$emit(REFRESH_TREE_USER)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
@import "../../styles/mixin.scss";
.v-wrapper {
  display: flex;
  align-items: stretch;
  padding: 0 30px;
  background-color: #000416;
  .v-nav {
    width: 250px;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
  }
  .v-body {
    width: 0;
    flex-grow: 1;
    padding: 0 45px;
    border-radius: 8px;
    box-sizing: border-box;
  }
}
</style>
